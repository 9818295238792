import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper'
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import {ReviewBudgetTable} from "../budget-table-review/ReviewBudgetTable";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectTotalYears,
  selectSavingStatus,
  selectBudgetNarrative,
  fetchBudgetIdAsyncAdmin,
  selectBudgetLoading,
  selectBudgetId,
  selectFinalReportId,
  selectProgressReports,
  selectNotFound,
  selectReportingPeriodNarrative
} from "../Common/stateSlices/budgetStateSlice";
import {
  fetchBlackbaudRequestRefAsync,
  fetchBlackbaudProgressReportRefAsync,
  fetchBlackbaudFinalReportRefAsync,
  selectBlackbaudRequestRef,
  fetchBlackbaudPaymentRefAsync,
  selectRequestStatus,
  selectRequestId,
  selectBlackbaudRequestData
} from "../Common/stateSlices/bbgDataSlice";
import { selectPrintScreenInfo, setPrintScreenClosed, selectPrintScreenOpen } from "../Common/stateSlices/appStateSlice";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDollarValues } from "../Common/helpers";
import { PrintHeader, printToPDF } from "../Common/pdfPrinting";

import { ProgressReportTable } from "../requirements-connector/requirements-connector-parts/progress-report-table/ProgressReportTable";
import { FinalReportTable } from "../requirements-connector/requirements-connector-parts/final-report-table/FinalReportTable";
import { Print } from "@mui/icons-material";

// testing data
const testingId = "bc5f059e-edc2-4fb9-a23f-aa7134dc7f34"
const testingKey = "e721369b-235f-411e-997a-4d595d46b74b"

const BudgetNarrative = () => {
  const BudgetNarrative = useAppSelector(selectBudgetNarrative)
  return (
    <Box component={Paper} sx={{margin: "2ch", minHeight: "200px", paddingX: "20px", paddingY: "10px"}}>
      { BudgetNarrative.split('\n').map( txt => <Typography key={txt}>{txt}</Typography>) } 
    </Box>
  )
}

const BudgetTableAndNarrative = () =>  {
  
  const [tab, setTab] = React.useState(0);
  const [printScreenOpen, setPrintScreenOpen] = React.useState(false);

  const years = useAppSelector(selectTotalYears);

  const handleSetPrintScreen = () => {
    setPrintScreenOpen(!printScreenOpen)
  }

  function PrintApplicationBudgetScreen(){
    const BlackbaudRequestRef = useAppSelector(selectBlackbaudRequestRef);
    const projectInfo = BlackbaudRequestRef
    const  {projectTitle} = projectInfo;
  
    const totalYears = useAppSelector(selectTotalYears);
    const budgetNarrative = useAppSelector(selectBudgetNarrative);
    const printWindowRef = useRef()
    const now = new Date().toLocaleDateString()
  
    useEffect(()=> {printToPDF(printWindowRef, "ApplicationBudget", handleSetPrintScreen)})
    return(
      <>
      <Box ref={printWindowRef}>
      <Typography
          variant="h2"
          sx={{marginTop: "76px", marginLeft: "2ch"}}
        >
          The Duke Endowment 
        </Typography>
        
        <Typography
          variant="h4"
          sx={{marginTop: "76px", marginLeft: "2ch"}}
        >
          {projectTitle}
        </Typography>
        <br/>
        <br/>
        <Typography variant="caption">Date: {now}</Typography>
        <ReviewBudgetTable years={totalYears} />
        <br/>
        {budgetNarrative 
          ? (
          <>
              <Typography
                variant="h4"
                sx={{ marginTop: "1ch", marginLeft: "2ch" }}
              >
                Narrative
              </Typography>
            <Box component={Paper} sx={{ margin: "4ch", minHeight: "200px", paddingX: "20px", paddingY: "10px" }}>
              {budgetNarrative.split('\n').map(txt => <Typography key={txt}>{txt}</Typography>)}
            </Box>
          </>
          )
          : null
        }
        </Box>
      </>
    )
  }

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) =>{
    console.log(newTab)
    newTab === 2 
      ? setPrintScreenOpen(true)
      : setTab(newTab)
  }

  const tabViews = [
    {
      label: "Budget",
      view: <ReviewBudgetTable years={years}/>,
    },
    {
      label: "Narrative",
      view: <BudgetNarrative/>
    }
  ];

  return (
    <>
    <Tabs
      value = {tab}
      onChange={handleChangeTab}
      textColor="secondary"
      indicatorColor="secondary"
      sx={{
        marginTop: '2ch',
        marginLeft: '4ch'
      }}
    >
      {tabViews.map( tab => <Tab key={tab.label} label={tab.label}/>)}
      <Tab icon={<Print/>}/>
    </Tabs>
    {tabViews[tab].view}
    {printScreenOpen ? <PrintApplicationBudgetScreen/> : null}
    </>
  )

}

const BudgetInfo = () => {
  let projectInfo = { organization: {name: ""}, contact: {name: ""}, projectTitle: "", disposition:"", requestAmount: 0, grantAmount: 0, paidAmount: 0, staff: {name: ''} }
  const bbgRequestStatus = useAppSelector(selectRequestStatus)
  const BlackbaudRequestRef = useAppSelector(selectBlackbaudRequestRef);
  if (bbgRequestStatus === 'loaded'){
    projectInfo = BlackbaudRequestRef
  }

  const { organization, projectTitle, contact, requestAmount, grantAmount, staff} = projectInfo;
  return(
    <TableContainer sx={{marginTop: "2ch"}} component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{width: "10%", minWidth: "20ch"}}>Organization:</TableCell>
            <TableCell>{organization.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Project Title:</TableCell>
            <TableCell>{projectTitle}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Contact:</TableCell>
            <TableCell>{contact.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Request:</TableCell>
            <TableCell>{formatDollarValues(requestAmount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Grant Amount:</TableCell>
            <TableCell>{formatDollarValues(grantAmount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Assigned Staff Member:</TableCell>
            <TableCell>{staff.name}</TableCell>
          </TableRow>
        </TableBody>

      </Table>
    </TableContainer>
  )
}

export const ApplicationAdmin = () => {
  const [tabNum, setTabNum] = React.useState(0);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(selectRequestStatus);
  const savingStatus = useAppSelector(selectSavingStatus);
  const blackbaudRequestRef = useAppSelector(selectBlackbaudRequestRef);
  const {projectTitle, orgName, grantNumber} = useAppSelector(selectBlackbaudRequestData);
  const notFound =  useAppSelector(selectNotFound);
  const narrative = useAppSelector(selectReportingPeriodNarrative)
  const printScreenOpen = useAppSelector(selectPrintScreenOpen)

  // constants for requirements
  const budgetLoading = useAppSelector(selectBudgetLoading);
  const budgetId = useAppSelector(selectBudgetId);
  const progressReports = useAppSelector(selectProgressReports);
  const finalReportId: number = useAppSelector(selectFinalReportId)
  const finalReportExists: boolean = finalReportId !== 0 && finalReportId !== null;
  const printscreenInfo = useAppSelector(selectPrintScreenInfo);

  const requestId = useAppSelector(selectRequestId);

  useEffect(() => {
    dispatch(fetchBlackbaudRequestRefAsync(id));
    dispatch(fetchBlackbaudRequestRefAsync(id));
    dispatch(fetchBudgetIdAsyncAdmin(id)) //dispatch(fetchBudgetIdAsyncAdmin(id))
    // dispatch(fetchBudgetIdAsyncConnector("8af2aaba-b596-4193-96de-9a7c78a7d394"));
  }, [id]);

  useEffect(() => {
    if(budgetLoading === "loaded"){
      // get Request from BBG
      dispatch(fetchBlackbaudRequestRefAsync(budgetId));
      // get usable Progress Reports
      progressReports.forEach(reportId => dispatch(fetchBlackbaudProgressReportRefAsync(reportId)));
      console.log("progressReports retrieved")
      console.log(finalReportExists, finalReportId)
      //get final report if it exists
      if(finalReportExists ){
        dispatch(fetchBlackbaudFinalReportRefAsync(finalReportId))
        console.log("finalReportRetrieved")
       }

    }
    
  }, [budgetLoading])

  useEffect(() => {
    if(requestStatus === "loaded"){

      dispatch(fetchBlackbaudPaymentRefAsync(requestId))
    }
  }, [requestStatus])


  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setTabNum(newValue);
  };

  const initialTabs = [
    {
      label: "Grant",
      view: <BudgetInfo/>,
    },
    {
      label: "Application Budget",
      view: <BudgetTableAndNarrative />,
    }
  ];

  const progressReportArr = progressReports.map((item, index) => ({label: `Progress Report ${index + 1}`, view: <ProgressReportTable progressReportId={item} disabled={true}/>}))
  const finalReportArr = finalReportExists ? [{label: "Final Report", view: <FinalReportTable disabled={true}/>}] : []

  const tabs = [
              ...initialTabs, 
              ...progressReportArr,
              ...finalReportArr
            ];

  const handlePrintScreenClose = () => {
    dispatch(setPrintScreenClosed());
  }

function PrintBudgetScreen(){
  console.log("printscreen fired");
  const printWindowRef = useRef()
  const now = new Date()
  const nowString = `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`
  const {printId, printReqType} = printscreenInfo
  const pdfName = printReqType === "Progress Report"
    ? `Grant ${grantNumber} Progress Report ID ${printId} ${nowString}`
    : `Grant ${grantNumber} Final Report ${nowString}`

  useEffect(()=> {
    printToPDF(printWindowRef, pdfName, handlePrintScreenClose);
  })

  const lineTwo = `${orgName}: ${projectTitle}`

  const returnObj = {progress: null, final: null}

  if (printReqType === "Progress Report"){
    returnObj.progress = (
      <>
        <PrintHeader typePrintBudget = {`Progress Report ID: ${printId}`} lineTwo = {lineTwo} grantNumber = {grantNumber}/>
        <br/>
        <Box sx={{ marginY: "4ch"}}>
            <Typography variant="h2" fontSize={"1.5rem"} marginLeft="5ch" fontWeight={400}>Budget</Typography>
            <ProgressReportTable progressReportId={printId} disabled={true} />
        </Box>
        {
          narrative(printId).length > 0 &&
          <Box sx={{ marginLeft: "8ch"}}>
            <Typography variant="h2" fontSize={"1.5rem"} fontWeight={400}>Budget Narrative</Typography>
            {narrative(printId).split('\n').map(txt => <Typography key={txt}>{txt}</Typography>)}
          </Box>
        }

      </>)

  }
  if (printReqType === "Final Report"){
    returnObj.final = (
      <>
        <PrintHeader typePrintBudget = "Final Report" lineTwo = {lineTwo} grantNumber = {grantNumber}/>
        <br/>        
        <Box>
          <FinalReportTable disabled={true}/>
        </Box>
      </>)
  }

  return (
    <Box ref={printWindowRef}>
      {returnObj.progress ? returnObj.progress: returnObj.final}
    </Box>
  )

}



  return (
    <>
      <Card variant="outlined" sx={{paddingX: "30px"}}>
      {!printScreenOpen ? null : <PrintBudgetScreen/>}
        {savingStatus === "saving" ? (
          <LinearProgress />
        ) : (
          <Box sx={{ height: "4px"}} />
        )}
        <CardHeader
          title={
            <>
              Budget Information for Grant Number {blackbaudRequestRef?.referenceNumber}
              {blackbaudRequestRef?.customFields
                ?.tde_budget_application_status ? (
                null
              ) : null}
            </>
          }
          subheader={"Please review the grant budget details."}
        />
        <Tabs indicatorColor="secondary" value={tabNum} onChange={handleChangeTab}>
          {tabs.map( tab => <Tab key={tab.label} label={tab.label}/>)}
        </Tabs>
        {tabs[tabNum].view}
        <CardContent>
          <Box sx={{ width: "100%" }}>
            {requestStatus !== "loaded" ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress sx={{ margin: "auto" }} />
              </Box>
            ) : notFound ? (
              <>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ml: 1 }}
                >
                  Not Found
                </Typography>
              </>
            ) : (
              <>
                <br/>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
