import React, {createContext, useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HelpIcon from "@mui/icons-material/Help";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import  FaqHelpContents from "../application-connector/Faq-Help/Faq-Help-Contents";
import {
  setRequirementSuccessMsg,
  selectRequirementSuccessMsg,
  setNarrativeOpen,
  setPrintScreenOpen,
  setDefinitionHelpers,
  selectDisableSubmit,
  selectDefinitionHelpers,
  selectPrintScreenOpen,
  setPrintScreenClosed,
  selectPrintScreenInfo,
} from "../Common/stateSlices/appStateSlice";
import {
  fetchBlackbaudRequestRefAsync,
  fetchBlackbaudProgressReportRefAsync,
  selectRequestId,
  selectRequestStatus,
  selectBlackbaudRequestData,
  // selectRequirementSuccessMsg,
  fetchBlackbaudPaymentRefAsync,
  selectRequirementStatus,
  selectFinalReportStatus,
  selectAnyProgressReportsStatus,
  selectPaymentsLoading,
  fetchBlackbaudFinalReportRefAsync
} from "../Common/stateSlices/bbgDataSlice";
import {
  addNewProgressReport,
  addNewFinalReport,
  upsertBudgetIdAsync,
  selectBudgetLoading,
  fetchBudgetIdAsync,
  selectBudgetId,
  selectFinalReportId,
  selectProgressReports,
  selectProgressReportKeys,
  setCurrentRequirement,
  selectReportingPeriodNarrative,
  selectCurrentProgressReport
} from "../Common/stateSlices/budgetStateSlice";
import { HelpfulDefinition } from "../Common/Definitions/HelpfulDefinitions";
import {
  FaqType
} from "../../app/models";
import { igamUrl } from "../../app/constants";
import { useParams, useSearchParams } from "react-router-dom";
import  Print  from "@mui/icons-material/Print";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { ProgressReportIntroduction } from "./Start-Page-Text/RequirementIntroductions";
import { RequirementsReportingPeriod } from "./requirements-connector-parts/requirements-reporting-period/RequirementsReportingPeriod";
import {RequirementTabs} from './RequirementTabs'
import {ProgressReportSubmit } from "./ProgressReportSubmit";
import {FinalReportSubmit } from "./FinalReportSubmit";
import { sendMsgToIGAM } from "../Common/helpers";
import { LoadingIcons } from "../Common/LoadingIcons";
import { ProgressReportTable } from "./requirements-connector-parts/progress-report-table/ProgressReportTable";
import { FinalReportTable } from "./requirements-connector-parts/final-report-table/FinalReportTable";
import { Alert, AlertTitle } from "@mui/material";
import { PrintHeader, printToPDF } from "../Common/pdfPrinting";


// pass CurBtnStatusContext to RequirementsDateInput component for "Continue" button enable/disable control
export const CurBtnStatusContext = createContext(null);

export const RequirementsConnector = () => {
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openFaq, setOpenFaq] = React.useState(false);
  const [openPrint, setOpenPrint] = React.useState(false);

  const printScreenOpen = useAppSelector(selectPrintScreenOpen)
  const { id, reqId, reqType } = useParams();
  let [searchParams] = useSearchParams()
  const searchParamIdType = searchParams.get('idtype')
  const override = searchParamIdType === "direct"
  const requirementId = Number(reqId)
  const {projectTitle, orgName, grantNumber} = useAppSelector(selectBlackbaudRequestData);
  const budgetLoading = useAppSelector(selectBudgetLoading);
  const SubmitDisabled = useAppSelector(selectDisableSubmit);
  const progressReports = useAppSelector(selectProgressReports);
  const anyProgressReportStatus = useAppSelector(selectAnyProgressReportsStatus)
  const budgetId = useAppSelector(selectBudgetId);
  const requestStatus = useAppSelector(selectRequestStatus); 
  const requirementSuccessMsg = useAppSelector(selectRequirementSuccessMsg);
  const requestId = useAppSelector(selectRequestId);
  const paymentsLoaded = useAppSelector(selectPaymentsLoading);
  const progressReportKeys = useAppSelector(selectProgressReportKeys);
  const requirementStatus = useAppSelector(selectRequirementStatus)
  const printscreenInfo = useAppSelector(selectPrintScreenInfo);
  const narrative = useAppSelector(selectReportingPeriodNarrative)
  const currentProgressReport: number = useAppSelector(selectCurrentProgressReport)
  const finalReportId = useAppSelector(selectFinalReportId);
  const finalReportExists = finalReportId !== 0 && finalReportId !== null;
  const finalReportLoading = useAppSelector(selectFinalReportStatus)
  const altBudgetId = "bc5f059e-edc2-4fb9-a23f-aa7134dc7f34"
  const altBudgetKey = "e721369b-235f-411e-997a-4d595d46b74b"

  const allLoaded = (anyProgressReportStatus === "loaded" || reqType !== "progress") &&
                    requestStatus === "loaded" &&
                    budgetLoading === "loaded" &&
                    paymentsLoaded === "loaded" &&
                    (finalReportLoading === "none" || finalReportLoading === "loaded" )


  const handlePrintScreen = () => {
    dispatch(setPrintScreenOpen({id: currentProgressReport , reqType: reqType === "progress"? "Progress Report" : "Final Report"}))
  }

  const handlePrintScreenClose = () => {
    dispatch(setPrintScreenClosed());
  }

function PrintBudgetScreen(){
  const printWindowRef = useRef()
  const now = new Date()
  const nowString = `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}`
  const {printId, printReqType} = printscreenInfo
  const pdfName = printReqType === "Progress Report"
    ? `Grant ${grantNumber} Progress Report ID ${printId} ${nowString}`
    : `Grant ${grantNumber} Final Report ${nowString}`

  useEffect(()=> {
    printToPDF(printWindowRef, pdfName, handlePrintScreenClose);
  })

  const lineTwo = `${orgName}: ${projectTitle}`

  const returnObj = {progress: null, final: null}

  if (printReqType === "Progress Report"){
    returnObj.progress = (
      <>
        <PrintHeader typePrintBudget = {`Progress Report ID: ${printId}`} lineTwo = {lineTwo} grantNumber = {grantNumber}/>
        <br/>
        <Box sx={{ marginY: "4ch"}}>
            <Typography variant="h2" fontSize={"1.5rem"} marginLeft="5ch" fontWeight={400}>Budget</Typography>
            <ProgressReportTable progressReportId={printId} disabled={true} />
        </Box>
        {
          narrative(printId).length > 0 &&
          <Box sx={{ marginLeft: "8ch"}}>
            <Typography variant="h2" fontSize={"1.5rem"} fontWeight={400}>Budget Narrative</Typography>
            {narrative(printId).split('\n').map(txt => <Typography key={txt}>{txt}</Typography>)}
          </Box>
        }

      </>)

  }
  if (printReqType === "Final Report"){
    returnObj.final = (
      <>
        <PrintHeader typePrintBudget = "Final Report" lineTwo = {lineTwo} grantNumber = {grantNumber}/>
        <br/>        
        <Box>
          <FinalReportTable disabled={true}/>
        </Box>
      </>)
  }

  return (
    <Box ref={printWindowRef}>
      {returnObj.progress ? returnObj.progress: returnObj.final}
    </Box>
  )

}

  const handleReceiveMsg = (message) => {
    if (message.origin === igamUrl) {
      switch (message.data.key) {
        case "get_setup_data":
          const {budgetID, budgetKey, reqId} = message.data.payload;
          if ( reqId === 0 ){
            console.log( "req ID was 0")
            return
          }
          dispatch(fetchBudgetIdAsync({ id: budgetID, admin: false, budgetKey }))
          reqType === "progress" && dispatch(setCurrentRequirement(Number(reqId)))
          break;
        // case 'tde_budget_application_status':
        //   dispatch(setApplicationSuccessMsg(true));
        //   break;
        default: console.log(message.data);
      }
    }
  };
  // add window listener on startup
  useEffect(() => {
    window.addEventListener('message', message => handleReceiveMsg(message))
    if (requestStatus === "idle") {
      sendMsgToIGAM({ key: 'get_setup_data' })
      if (id === "1234a") {
        dispatch(fetchBudgetIdAsync({ id: altBudgetId, admin: false, budgetKey: altBudgetKey }))
        dispatch(setCurrentRequirement(Number(reqId)))
      }
      if (override) {
        console.log(id)
        dispatch(fetchBudgetIdAsync({ id: id, admin: true, budgetKey: "ignore" }))
        dispatch(setCurrentRequirement(Number(reqId)))
      }
    }; 
    window.addEventListener('beforeunload', (event) => {
      if (!requirementSuccessMsg) {
        event.preventDefault();
        event.returnValue = '';
      }
    });

  }, [id]);

// get BBG Request data if successful budget load
  useEffect(() => {
    if(budgetLoading === "loaded"){
      // get Request from BBG
      dispatch(fetchBlackbaudRequestRefAsync(budgetId))
      // get usable Progress Reports
      switch(reqType){
        case "progress":
          //get relevant progress reports

          // handles creating a new progress report if needed, and getting progress reports from BBG
          if (!progressReports.includes(requirementId)){ //new progress report
            dispatch(addNewProgressReport());
            [...progressReports, requirementId].forEach(reportId => dispatch(fetchBlackbaudProgressReportRefAsync(reportId)))
          } else {  // existing progress reports
           progressReports.forEach(reportId => dispatch(fetchBlackbaudProgressReportRefAsync(reportId)))
           if(finalReportExists ){
            dispatch(fetchBlackbaudFinalReportRefAsync(finalReportId))
           }
          }
          break;
        case "final":
          //get all progress reports
          if (!finalReportExists){
            console.log("creating new final report")
            dispatch(addNewFinalReport(requirementId))
          }
          progressReports.forEach(reportId => dispatch(fetchBlackbaudProgressReportRefAsync(reportId)));
          dispatch(fetchBlackbaudFinalReportRefAsync(requirementId))
          break;
        default:  
      }
    }
    
  }, [budgetLoading])

  useEffect(() => {
    if( (anyProgressReportStatus === "loaded" || "none") && requestStatus === "loaded"){
      dispatch(fetchBlackbaudPaymentRefAsync(requestId))
    }
  }, [anyProgressReportStatus, requestStatus])

  const DefinitionHelpers = useAppSelector(selectDefinitionHelpers);
  //control disable Continue Button or not
  const [notDisableBtn, setNotDisableBtn] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNotDisableBtn(false)
  };

  const progressSteps = [
    {
      label: "Let's get started",
      content: <ProgressReportIntroduction reqType="progress"/>,
    },
    {
      label: "Reporting Period",
      // Context Provider is used for updating notDisableBtn from "RequirementsDateInput", if date input format is wrong
      content: <CurBtnStatusContext.Provider
                value={setNotDisableBtn}
               >
                <RequirementsReportingPeriod />
               </CurBtnStatusContext.Provider>
    },
    {
      label: "Budgets",
      content: <RequirementTabs  />
    },
    {
      label: "Submit",
      content: <ProgressReportSubmit />
    }
  ];

  const finalSteps = [
    {
      label: "Let's get started",
      content: <ProgressReportIntroduction reqType="final" />,
    },
    {
      label: "Final Report",
      content: <RequirementTabs />
    },
    {
      label: "Submit",
      content: <FinalReportSubmit />
    }
  ];

  const steps = reqType === "progress"
    ? progressSteps
    : finalSteps



  // handle budget Narrative
  const handleClickOpen = (e) => {
    e.target.id === 'narrative' && dispatch(setNarrativeOpen(true));
    e.target.id === 'faq' && setOpenFaq(true);
    e.target.id === 'printScreen' && setOpenPrint(true)
  };
  const handleClose = (e) => {
    e.target.id === 'narrativeClose' && setOpen(false);
    e.target.id === 'faqClose' && setOpenFaq(false);
    e.target.id === 'printClose' && setOpenPrint(false)
  };

  const handleAddToRequirement = () => {
      dispatch(upsertBudgetIdAsync({ done: true }))
  };

  const handleCloseRequirementSuccessMsg = () => {
    dispatch(setRequirementSuccessMsg(false))
  }

  const handleCloseDef = () => {
   dispatch(setDefinitionHelpers({visible: false, key: ""}))
  }

  const handleSaveDraft = (event) => {
    dispatch(upsertBudgetIdAsync({ done: false }));
  };
  const header = (
    <MuiAppBar position="absolute" sx={{ bgcolor: "white" }} elevation={0}>
      <Toolbar>
        <img
          style={{ height: 60, margin: '8px auto' }}
          src="/logo.png"
          alt="The Duke Endowment"
        />
      </Toolbar>
    </MuiAppBar>
  )

  return (
    <>
      {header}
      {!printScreenOpen
        ? (
          <>
          <Card variant="outlined" sx={{ mt: "80px" }}>
            <CardHeader
              title={reqType === "progress" 
                ?"The Duke Endowment Budget Portal - Progress Report"
                : "The Duke Endowment Budget Portal - Final Report"
              }
                subheader={reqType === "progress" 
                  ?"Please follow the steps below to submit your progress report."
                  : "Please follow the steps below to submit your final report."
            }
            />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography sx={{ml: "20px"}} gutterBottom variant="subtitle1" component="div">
                    <b>  {projectTitle}</b> - Grant #{grantNumber}
                  </Typography>
                  <Typography sx={{ml: "20px", fontStyle:"italic"}} gutterBottom variant="subtitle2" component="div">
                    {orgName}
                  </Typography>
                </Box>
                <Box>
                  {/* <Button
                    size="large"
                    id="printScreen"
                    sx={{ float: "right" }}
                    endIcon={<SaveRoundedIcon />}
                    variant="text"
                    onClick={handlePrintScreenClose}
                  >
                    Print
                  </Button> */}
                  <Button
                    size="large"
                    sx={{ float: "right" }}
                    endIcon={<SaveRoundedIcon />}
                    variant="text"
                    onClick={handleSaveDraft}
                  >
                    Save
                  </Button>
                </Box>
                
              </Box>

              <Stepper
                sx={{ marginTop: 2, mb: 2 }}
                activeStep={activeStep}
                orientation="horizontal"
              >
                {steps.map((step, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={step.label} {...stepProps}>
                      <StepLabel {...labelProps}>{step.label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {steps[activeStep].content}
              <Stack direction="row" justifyContent="space-between" sx={{ mx: "auto", mb: 2, mt: 4, width: "calc(100vw*.9)" }}>
                <Button
                  size="large"
                  onClick={handleBack}
                  sx={{
                    mt: 1,
                    mr: 1,
                    display: activeStep === 0 ? "none" : null,
                  }}
                >
                  Back
                </Button>
                <Box>
                  <Button
                    size="large"
                    onClick={handleSaveDraft}
                    // disabled={savingStatus === "saving"}
                    sx={{
                      mt: 1,
                      mr: 1,
                      display:
                        activeStep !== steps.length - 1 ? "none" : null,
                    }}
                  >
                    Save Draft
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    onClick={handleAddToRequirement}
                    disabled={SubmitDisabled}
                    sx={{
                      mt: 1,
                      display:
                        activeStep !== steps.length - 1 ? "none" : null,
                    }}
                  >
                    { reqType === "progress" 
                      ?"Add to Progress Report!"
                      :"Add to Final Report!"
                  }
                  </Button>
                </Box>
                <Box sx={{
                        mt: 1,
                        alignSelf: "right",
                        float: "right",
                        display: activeStep === steps.length - 1? "none" : null,
                }}>
                  <Button
                    id="narrative"
                    size="large"
                    variant="contained"
                    onClick={handleClickOpen}
                    sx={{
                      mr: 2,
                      display:
                        activeStep === 2 ? null : "none",
                    }}
                  >
                    NARRATIVE
                  </Button>

                  <Button
                  size="large"
                  disabled = {notDisableBtn}
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    display:
                      activeStep === steps.length - 1 ? "none" : null,
                  }}
                  >
                    Continue
                  </Button>
                </Box>
              </Stack>
          </Card>
          <Button
            id="faq"
            size="large"
            sx={{ float: "right", textTransform: "none" }}
            startIcon={<HelpIcon />}
            variant="text"
            onClick={handleClickOpen}
          >
            FAQs and Help
          </Button>
{/* Budget Narrative */}
          {/* <Dialog
            fullWidth={true}
            maxWidth={false}
            open={open}
            onClose={handleClose}
          >
            <DialogContent  sx={{paddingBottom: 0}}>
              <DialogContentText component="div">
                  <BudgetNarrative/>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{paddingTop: 0,}}>
              <Button id="narrativeClose" variant="contained" onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog> */}
{/* Definition Pop Up */}
          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={DefinitionHelpers.visible}
            onClose={handleCloseDef}
            id="definition"
          >
            <DialogContent>
              <DialogContentText component="div">
                  <HelpfulDefinition definitionId={DefinitionHelpers.key}/>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDef}>Close</Button>
            </DialogActions>
          </Dialog>
            {/* Add to application success */}

          <Dialog
            fullWidth={false}
            maxWidth={false}
            open={requirementSuccessMsg}
            onClose={handleCloseRequirementSuccessMsg}
            id="successfully-added"
          >

            <DialogTitle>
              <Typography align="center" variant="h2" sx={{ fontSize: "4ch", color: "rgb(117, 106, 78)" }} >
                {reqType === "progress" ? "Progress Report ": "Final Report "}Budget Added!
              </Typography>

            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                You have successfully completed your budget! <br />
                Please save this as a PDF using the Print to PDF button (legal paper size, horizontal format)<br /> and attach it to your report in the Attachments tab under Uploads as a Budget Form. <br />
                You can re-attach a revised budget, if you have made changes, until the {reqType === "progress" ? "progress report ": "final report "} has been submitted.
              </Typography>

            </DialogContent>
            <DialogActions>
              <Button
                endIcon={<Print />}
                variant="text"
                onClick={handlePrintScreen}
              >
                Print PDF
              </Button>
            </DialogActions>
          </Dialog>

{/* loading screen */} 
          <Dialog
            open={!allLoaded}
            onClose={handleCloseDef}
            id="definition"
          >
            <DialogTitle sx={{color: "primary"}}>Loading</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                  <LoadingIcons state={budgetLoading} loadingTarget="Budget Data"/>
                  <LoadingIcons state={requestStatus} loadingTarget="Request Data"/>
                  {requestStatus === "loaded" && anyProgressReportStatus !== "none" &&
                  <>
                    <LoadingIcons state={anyProgressReportStatus} loadingTarget="Progress Reports" />
                    <Stack spacing={1.3} sx={{paddingLeft: "20px"}}>
                        {progressReportKeys.map(key => {  return <LoadingIcons key={key} state={requirementStatus(key)} loadingTarget={`Progress Report ${key}`} />}  )}
                    </Stack>
                  </>}
                  <LoadingIcons state={finalReportLoading} loadingTarget="Final Report"/>
                {anyProgressReportStatus === "loaded" && <LoadingIcons state={paymentsLoaded} loadingTarget="Payments Data"/>}
              </Stack>
              { (requestStatus === "failed"  ||  budgetLoading === "failed" || anyProgressReportStatus === "failed" || paymentsLoaded ==="failed") && 
                <Stack>
                  <Alert severity="error">
                    <AlertTitle>Uh oh... it looks like there was an issue loading some of your data.</AlertTitle>
                    You appear to have an issue loading the following items.
                    <ul>
                      {requestStatus === "failed" && <li>Request</li> }
                      {budgetLoading === "failed" && <li>Budget</li>}
                      {anyProgressReportStatus === "failed" && <li>Progress Reports</li>}
                      {paymentsLoaded === "failed" && <li>Payments</li>}
                      {false && <li>Final Report</li>}
                    </ul>
                    Please try to first refresh this screen. If the problem persists, please reach out to your contact at the Duke Endowment with which item or items are not loading. 
                  </Alert>
                </Stack>
              }

            </DialogContent>
          </Dialog>
{/* FAQ & Help */}
          <Dialog
            fullWidth={true}
            maxWidth={false}
            open={openFaq}
            onClose={handleClose}
            id="FAQs-and-help"
          >
            <DialogTitle>
              FAQs and Help
              <HelpIcon sx={{ float: "right" }} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText component="div">
                  <FaqHelpContents faqType={FaqType.requirement}/>
                    {/* <FaqHelp budgetType={BudgetType}/> */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button id="faqClose" onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
          </>
        )
        :(
          <PrintBudgetScreen/> 
        )
      }
    </>
  );
};
